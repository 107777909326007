* {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

body {
  display: block !important;
  background-color: white !important;
}

.row {
  display: unset !important;
  flex-wrap: unset !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

footer,
button,
input[type=checkbox],
#sidebar,
#search,
#noteForm,
.app-navbar,
.async-refresh-indicator,
.pagination-wrap,
.btn,
.nav-tabs,
.checkout-block,
.hubspot {
  display: none !important;
}

.layout-inner,
.body-page {
  display: block !important;
}

.layout-container {
  display: block !important;
  padding: 0 !important;
}

.body-container {
  display: block !important;
  margin: 0 !important;
  overflow: visible !important;
}

.table-responsive {
  overflow: visible !important;
}

div.show-image.collection {
  min-height: 200px !important;
}

.col-lg-3 {
  padding: 0 !important;
}

.quality-table .first {
  border-left: 0 !important;
}

.card {
  page-break-inside: auto !important;
  border: 0 !important;
  background-color: unset !important;
}

div.show-image.jacket-image.loaded {
  background-size: contain !important;
  background-position: left !important;
  background-color: unset !important;
}

div#hubspot-messages-iframe-container {
  display: none !important;
}

@page {
  margin: 2cm !important;
}

.subtitle {
  text-align: center !important;
}
